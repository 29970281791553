<template>
	<div class="wrap">
		<div class="flex justify-between">
			<!-- <el-form :inline="true" :model="searchForm">
				<el-form-item>
					<el-date-picker v-model="daterange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="submitSearch()">查询</el-button>
				</el-form-item>
			</el-form> -->
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="order_no" label="订单编号" width="280">
			</el-table-column>
			<el-table-column prop="name" label="服务名称" width="180">
			</el-table-column>
			<el-table-column prop="technician_name" label="技师" width="180">
			</el-table-column>
			<el-table-column prop="username" label="会员姓名" width="180">
			</el-table-column>
			<el-table-column prop="symptom" label="症状" width="180">
			</el-table-column>
			<el-table-column prop="suggest" label="建议" width="180">
			</el-table-column>
			<el-table-column prop="record_time" label="服务时间">
			</el-table-column>
			<!-- <el-table-column prop="status" label="状态">
				<template slot-scope="scope">
					<div class="text-center text-blue" v-if="scope.row.status == 0">未付款</div>
					<div class="text-center text-green" v-if="scope.row.status == 1">已付款</div>
				</template>
			</el-table-column> -->
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				data: {},
				tableData: [], // 数据
				total: 0,
				searchForm: {
					page: 1,
					page_size: 10,
					start_date: '',
					end_date: ''
				},

			};
		},
		computed: {
			daterange: {
				get() {
					return [this.searchForm.start_date, this.searchForm.end_date]
				},
				set(val) {
					if (val) {
						this.searchForm.start_date = val[0] + ' 00:00:00'
						this.searchForm.end_date = val[1] + ' 23:59:59'
					} else {
						this.searchForm.start_date = ''
						this.searchForm.end_date = ''
					}
				}
			}
		},
		mounted() {
			// 查询数据
			this.search()
		},
		methods: {
			submitSearch(){
				this.searchForm.page = 1;
				this.search()
			},
			search() {
				this.loading = true;
				this.$api.staff.getRecords(this.searchForm).then(res => {
					this.total = res.data.count;
					this.data = res.data;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},

		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}

	.sx {
		height: 18px;
		width: 1px;
		background-color: #ccc;
		margin: 0 10px;
	}

	.tongji {
		height: 40px;
		align-items: center;
	}
</style>
